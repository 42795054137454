import { cva, type VariantProps } from "./style.utils";

export type SpinnerVariants = VariantProps<typeof spinnerVariants>;

export const spinnerVariants = cva({
  base: [
    // layout
    "shrink-0",
    // animations
    "animate-spin",
  ],
  variants: {
    palette: {
      unset: null,
      accent: "text-link-hover first:*:stroke-shade-100",
      error: "text-error-100",
      warning: "text-warning-100",
      success: "text-success-100",
      info: "text-info-100",
      neutral: "text-shade-100",
      white: "text-white",
    },
    inline: {
      true: "inline",
      false: "",
    },
    size: {
      sm: "size-18px",
      md: "size-66px",
      lg: "size-80px",
    },
  },
  defaultVariants: {
    palette: "accent",
    inline: false,
    size: "md",
  },
});
