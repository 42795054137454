import { forwardRef } from "react";
import { spinnerVariants, type SpinnerVariants } from "./spinner.styles";

export type SpinnerRef = React.ElementRef<"svg">;

export interface SpinnerProps
  extends Omit<
      React.ComponentPropsWithoutRef<"svg">,
      "fill" | "height" | "stroke" | "viewBox" | "width"
    >,
    SpinnerVariants {}

const Spinner = forwardRef<SpinnerRef, SpinnerProps>(
  ({ className, inline, palette, size, ...props }, ref) => {
    return (
      <svg
        aria-label="Loading…"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        className={spinnerVariants({ className, inline, palette, size })}
        ref={ref}
      >
        <circle cx="12" cy="12" r="11" strokeOpacity="0.08" />
        <path d="M23 12C23 5.92487 18.0751 1 12 1" />
      </svg>
    );
  },
);

Spinner.displayName = "Spinner";

export { Spinner };
