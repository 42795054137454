import { forwardRef } from "react";
import { logoLayoutMap } from "./logo.components";
import { type LogoVariants } from "./logo.styles";

export type LogoRef = React.ElementRef<"svg">;

export interface LogoProps
  extends Omit<
      React.ComponentPropsWithoutRef<"svg">,
      "fill" | "width" | "height" | "viewBox" | "xmlns"
    >,
    LogoVariants {}

const Logo = forwardRef<LogoRef, LogoProps>(({ layout, ...props }, ref) => {
  const Comp = logoLayoutMap[layout ?? "mark-and-word"];
  return <Comp {...props} ref={ref} />;
});

Logo.displayName = "Logo";

export { Logo };
